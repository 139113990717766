.nav-container{
    position: fixed;
    top: 0;

    padding: 0 3rem 0 5rem;

    width: 100%;
    height: clamp(80px,8vh,8vh);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    z-index: 3;

    transition: 0.6s ease-in-out;

    /* border: 2px solid red; */
}

.hidden{
    top:-10vh;
    transition: 0.2s ease-in-out;
}

.img-test{
    height: clamp(80px,5vh,5vh);
    width: auto;

    mix-blend-mode: multiply;

    cursor: pointer;

    /* border: 2px solid red */
}

.nav-btns{
    /* min-width: 10%; */
    width: fit-content;
    height: fit-content;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    background-color: white;
    /* border: 2px solid var(--templateContrast); */
    border-radius: 30px;
}

.nav-btns>a>h2{
    font-size: 0.7rem;
    cursor: pointer;

    padding: 0.5rem 2rem;

    /* border-radius: 20px; */
}

.nav-btns>a>h2:nth-child(1){
    border-radius: 20px 0 0 20px;
}

.nav-btns>a>h2:last-child{
    border-radius: 0 20px 20px 0;
}

.nav-btns>a>h2:hover{
    /* background-color: var(--templateBackgroundAlt); */
    color: var(--textColorAlt);
}

/* ////////////////////////////// */

.nav-btns>h2{
    font-size: 0.7rem;
    cursor: pointer;

    padding: 0.5rem 2rem;

    /* border-radius: 20px; */
}

.nav-btns>h2:nth-child(1){
    border-radius: 20px 0 0 20px;
}

.nav-btns>h2:last-child{
    border-radius: 0 20px 20px 0;
}

.nav-btns>h2:hover{
    /* background-color: var(--templateBackgroundAlt); */
    color: var(--textColorAlt);
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
    .nav-container{
        padding: 0 1rem 0 1rem;
        width: 100%;
        /* border: 0.5px solid red; */
    }

    .hidden{
        top:-20vh;
        transition: 0.2s ease-in-out;
    }

    .img-test{
        height: clamp(50px,3vh,3vh);
        /* border: 2px solid red */
    }
    .nav-btns>h2{
        font-size: 0.7rem;
        padding: 0.4rem 1rem;
        /* border: 0.5px solid red; */
    }
}

@media only screen and (max-width: 786px ){
    .img-test{
        height: clamp(40px,3vh,3vh);
        /* border: 2px solid red */
    }
    .nav-btns>h2{
        font-size: 0.5rem;
        padding: 0.3rem 0.7rem;
        /* border: 0.5px solid red; */
    }
}