:root{
    --sliderHeight: 80vh;
    --sliderWidth: 70vw;
}

.prod-nav-container{
    width: 100%;
    padding-right:40vw;
    height: 10vh;
    align-self: flex-start;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    background-color: var(--templateBackgroundAlt);
    border: 0.5px solid var(--textColorAlt);
    border-bottom: none;
    /* border-right: none; */
}

.prod-nav-btns{
    padding: 1rem 2rem;
    cursor: pointer;

    transition: 0.3s ease-in-out;

    /* border: 1px solid red; */
}

.prod-nav-btns:hover{
    transition: 0.3s ease-in-out;
    color: var(--templateContrast);
}

.prod-cat-nav-btns{
    position: relative;
    align-self: flex-end;

    padding-left: 1.5rem;

    width: 80%;
    height: 4rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    /* border: 0.5px solid blue; */
}

.vertical-text{
    position: relative;

    display: flex;
    width: 100%;
    min-height: var(--sliderHeight);
    height: 100%;
    /* border: 0.5px solid blue; */
}

.small-header{
    position: absolute;
    top: 0;
    left: 1vw;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;

    width: 5vw;
    height: 2rem;

    /* border: 1px solid green; */
}

.text{
    position:absolute;
    top: 0;
    left: 0;

    padding: 0 5rem;

    display: flex;
    /* flex-direction: row; */
    justify-content:flex-end;
    align-items: center;

    width: var(--sliderHeight);
    /* width: fit-content; */
    height: 5vw;

    font-size: 3rem;
    text-align: center;

    transform: rotate(-90deg)translateX(-100%);
    transform-origin: left top;
    /* border: 2px solid red; */
}

.description{
    position: relative;
    width: 60vw;
    height: 100%;

    padding:0 2rem;
    /* padding: 3rem 0 0 1rem; */

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left:5vw;

    font-size: 1rem;

    /* border-right: 0.5px solid var(--textColorAlt); */

    /* border: 1px solid blue; */
}

.description::after{
    content: "";
    position: absolute;
    width: 0.5px;
    height: calc(100% + 10vh);
    transform: translateY(-10vh);
    right: 0;
    top:0;
    background-color: var(--textColorAlt);
}

.know-more{
    width: 35vw;
    height: 100%;

    padding:0 2rem 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;
    border-right: none;

    /* border: 1px solid red; */
}

.know-more>p{
    text-align: center;
}
/* ///////////////////////////////////////// */
/* //////////////////Slider///////////////// */
/* ///////////////////////////////////////// */

.prod-slider-container{
    position: relative;
    width: 100%;
    min-height: var(--sliderHeight);
    height: auto;
    justify-self: flex-end;

    /* border-top: 0.5px solid var(--textColorAlt); */
    /* border-right: 0.5px solid var(--textColorAlt); */
}
.prod-slider-container::after{
    content: "";
    position: absolute;
    width: 90%;
    height: 0.5px;
    /* transform: rotate(90deg); */
    /* transform: translateY(10%); */
    right: 0;
    top:0;
    background-color: var(--textColorAlt);

}

.prod-slider-wrapper{
    width: 100%;
    min-height: 90vh;
    height: auto;
    padding: 0 5vw;
    /* border: 1px solid green; */
}

.prod-slider-hidden{
    display: none;
}

.slider-icon{
    /* position: absolute; */
    position: relative;
    /* bottom: 1rem; */

    color: var(--textColorAlt);
    font-weight: light;
    font-size: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */

    cursor: pointer;
    /* border: 0.5px solid red; */
}

.left-icon{
    margin-right: 1rem;
}

.right-icon{
    margin-left: 1rem;
}

.product-item{
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    padding: 1rem 2vw 0.5rem 0;
    /* margin:0 5vw; */

    opacity: 1;
    transition: all 0.3s ease-in-out;
    /* margin-right: 2vw; */
    /* border: 0.5px solid red; */
}
.application-slider{
    margin-top: 2vh;
    height: auto;
    /* height: fit-content; */
}

.application-slider::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 0.5px;
    /* transform: rotate(90deg); */
    /* transform: translateY(10%); */
    left: -5vw;
    top:0;
    background-color: var(--textColorAlt);

}

.render-div{
    opacity: 0;
    /* display: none; */
    transition: all 0s ease-in;
}

.prod-img{
    width: 40%;
    height: var(--sliderHeight);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.2s ease-in;
    /* border: 1px solid purple; */
}

.prod-img>img{
    width: 100%;
    height: 100%;
    align-self: flex-start;
    /* aspect-ratio: 1/2; */
    object-fit:contain;
}

.prod-desc-container{
    position: relative;

    width: 60%;
    min-height: var(--sliderHeight);
    height: 100%;

    display: grid;
    grid-template-columns: 60% 40%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid purple; */
}

.product-spec-container{
    min-height: fit-content;
    grid-template-columns: 100%;
    padding-bottom: 5rem;
}

.prod-index-grid{
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    margin-bottom: 2rem;
    justify-content: space-between;
    /* border: 1px solid green; */
}

.prod-index-grid-alt{
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    margin-bottom: 2rem;
    justify-content: space-between;
    /* border: 1px solid green; */
}

.prod-name{
    font-size: 2rem;
    font-weight: bold;

    margin-bottom: 0.5rem;
}

.prod-desc{
    font-size: 0.8rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
}

.prod-spec-container{
    position: relative;
    width: 100%;
    height: auto;

    margin-top:3rem;
    margin-bottom: 3rem;

    display: grid;
    grid-template-columns: 100%;
    row-gap: 2rem;

    /* border: 1px solid red; */
}



.prod-specs,.prod-applications{
    width: 100%;
    height: fit-content;

    display: grid;
    grid-template-columns: 100%;
    row-gap: 0.5rem;

    padding-left: 1rem;

    font-size: 0.7rem;
    font-weight: bold;
}

.prod-sizes{
    width: 70%;
    height: auto;
    
    display: grid;
    grid-template-columns: 30px auto auto auto auto auto auto;
    /* justify-content:center; */
    align-items: center;

    font-size: 0.7rem;
    font-weight: bold;
    text-align: center;

    /* margin-left: 1rem; */

    /* border: 1px solid red; */
}

.prod-icon{
    width: 1.5rem;
    /* aspect-ratio: 1/1; */
    height: 1.5rem;

    background-color: var(--templateContrast);
    color: white;

    padding: 0.2rem 0;

    display: flex;
    justify-content: center;
    align-items: center;
    /* color:#e0a73d; */
    /* border: 1px solid blue; */
}

.check{
    padding: 0.4rem 0;
}


/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1200px ){
    .prod-nav-btns{
        padding: 1rem 1rem;
    }
}
@media only screen and (max-width: 1000px ){
    .small-header{
        font-size: 0.8rem;
    }

    .vertical-text{
        /* flex-direction: column; */
        height: max-content;
    }
    .prod-nav-btns{
        padding: 1rem 1rem;
        font-size: 1rem;
    }

    .description{
        width: 90%;
        padding: 0 2rem 1rem 3rem;
    }

    .description::after{
        content: "";
        width: 0px;
        /* border: 0.5px solid red; */
    }

    .know-more{
        width: 100%;
        padding:1rem 2rem 1rem 3rem;
        /* border: 0.5px solid red; */
    }

    .know-more>p{
        margin-bottom: 1rem;
    }

    .product-item{
        flex-direction: column;
    }
    .prod-img{
        position: relative;
        width: 100%;
        height: 60vh;
    }

    .prod-sizes{
        width: 80%;
    }

    .prod-desc-container{
        position: relative;
    
        width: 100%;
    }

}

@media only screen and (max-width: 786px ){
    .prod-nav-container{
        width: 100%;
        padding-right:0vw;
    }
    .prod-nav-btns{
        padding: 1rem 0.5rem;
        font-size: 0.8rem;
    }
    .small-header{
        font-size: 0.6rem;
        width: 10vw;
    }
    
    .text{
        left: 0.5rem;
        font-size: 2rem;
    }
    
    .know-more{
        align-self: center;
        position: relative;
    }
    
    .know-more>p{
        padding-left: 5vw;
        /* border: 0.5px solid red; */
    }
    
    .know-more::after{
        content: "";
        position: absolute;
        width: 80%;
        height: 0.5px;
        /* transform: rotate(90deg); */
        /* transform: translateY(10%); */
        right: 0;
        top:0px;
        background-color: var(--textColorAlt);
    }
    .prod-slider-container::after{
        width: 80%;
    }
    
    .vertical-text{
        flex-direction: column;
        /* justify-content: center; */
    }
    .prod-desc-container{
        padding-left: 2rem;
        grid-template-columns: 100%;
    }

    .prod-index-grid,.prod-index-grid-alt{
        grid-template-columns: 100%;
    }
    .size-icon{
        grid-column: 1/ span 5;
        margin-bottom: 1rem;
    }
    .prod-sizes{
        padding-left: 1rem;
        grid-template-columns: auto auto auto auto auto;
        justify-content: flex-start;
        column-gap: 0.5rem;
        width: 100%;
    }
}