.about-content-grid{
    width: 100%;

    padding: 0 0 5vh 0;

    display: grid;
    grid-template-columns: 40% 60%;
    /* border: 1px solid white; */
}

.about-content-grid-alt{
    grid-template-columns: 60% 40%;
}

.about-text{
    width: 50%;
    display: flex;
    
    flex-direction: column;
    padding: 2rem;
    
    /* border: 1px solid red; */
}

.left-side-text{
    width:80%;
    justify-self: flex-end;
    text-align: right;
    /* border: 1px solid red; */
}

.right-side-text{
    justify-self: flex-start;
    text-align: left;

    width: 70%;
    /* border: 1px solid red; */
}

.full-cover-text{
    width: 50%;

    justify-self: center;
    grid-column: 1/ span 2;

    text-align: center;

    /* border: 1px solid red; */
}


.about-img-container{
    display: flex;
    justify-content: center;
    align-items: center;

    /* border: 1px solid red; */
    /* grid-column: 1/span 2; */
}

.left-about-img{
    width: 100%;
    padding-right: 2rem;
    /* justify-content: left; */
}

.right-about-img{
    justify-content: right;
    padding-left: 2rem;
}

.about-img{
    /* width: 58%; */
    width: 100%;
    height: auto;

    object-fit: contain;
}


.about-img-right{
    width: 60%;
    height: auto;
    padding-left: 2rem;
    
    object-fit: contain;
}

.small-about>p{
    font-size: 1rem;
    padding: 0;
}

.medium-about>p{
    font-size: 1.2rem;
}

.big-about>p{
    font-size:1.8rem;
}

.big-about>h1{
    font-size:3rem;
    font-weight: bold;
}

.quotes-text{
    quotes:'\201C' '\201D';
    font-size: 2rem;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
    .about-text{
        padding: 0.8rem;
    }
    .full-cover-text{
        width: 80%;
    }
    .big-about>p{
        font-size:1.2rem;
    }
    .big-about>h1{
        font-size:1.8rem;
        font-weight: bold;
    }
    .medium-about>p{
        font-size: 0.8rem;
    }
    .quotes-text{
       font-size: 1.2rem;
    }
}

@media only screen and (max-width: 786px ){
    .about-text{
        width: 50%;
        display: flex;
        
        flex-direction: column;
        padding: 0.5rem;
    }
    .left-side-text{
        width:100%;
        /* border: 0.5px solid blue; */
    }
    .right-side-text{
        width: 100%;
        /* border: 0.5px solid red; */
    }
    .full-cover-text{
        width: 80%;
    }

    .big-about>p{
        font-size:1rem;
    }
    .big-about>h1{
        font-size:1.1rem;
        font-weight: bold;
    }
    .medium-about>p{
        font-size: 0.7rem;
    }
    .quotes-text{
        font-size: 0.8rem;
    }
    .about-img-right{
        width: 80%;
        height: auto;
    }
}