.form-container{
    margin-top: 2rem;
    width: 80%;
    padding: 0 1rem;
    
    /* border: 2px solid red; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.alert-submit-box{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    /* justify-content: space-between; */

    /* border: 2px solid green; */

}

.error-content{

    width: fit-content;
    height: auto;
    
    /* font-size: 1rem; */
    /* color: var(--textColorAlt); */

    background-color: transparent;
    /* border: 2px solid blue; */
}

.form-grid{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 90%;
    /* border: 2px solid red; */
}

.form-group{
    padding: 0.5rem 0.5rem 0.5rem 0;
    position: relative;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;

    /* transition: 0.6s ease-in-out; */
    /* border: 2px solid blue; */
}

.form-group>label{
    font-size: 0.8rem;
}

.form-control{
    width: 100%;
    padding: 0.6rem 1rem;
    
    background-color: transparent;
    box-shadow: none;
    border: 1px solid var(--textColorAlt);
    border-radius: 20px;

    font-family: 'Albert Sans', sans-serif;

    color: black;
    font-size: 0.8rem;
    font-weight: 500;
}

.form-control:focus{
    /* content: :""; */
    background-color: transparent;
    border: 1px solid var(--textColorAlt);
    /* border-bottom: 1px solid rgb(192, 192, 192); */
    outline: none;
}
.faq-form-control:focus{
    background-color: var(--templateBackground);
}

.form-control:focus::placeholder{
    color: transparent;
}

.form-control::selection{
    color:var(--textColorAlt);
    background: rgb(141, 141, 141);
}

.form-group-textarea{
    grid-column: 1 / span 2;
}

.form-group-textarea>label{
    align-self: flex-start;
}

.form-control-textarea{
    width: 100%;
    text-align: justify;
    /* border-bottom: 1px solid rgba(167, 167, 167, 0.5); */
    resize: none;
}

.fill-space{
    width: 100%;
    /* border: 1px solid blue; */
}

.error-alert{
    padding: 0.5rem 1rem;

    position: absolute;
    width: fit-content;
    height: fit-content;
    top:0.6rem;
    left: 0%;

    background-color: #e63946;
    border: none;
    border-radius: 20px;
    outline: none;

    color: var(--templateBackground);
    /* cursor: pointer; */
    font-size: 0.8rem;
    transition: 0.3s ease-in-out;
}

.success-alert{
    text-align: center;
    background-color:#118ab2;
}

.faq-error-alert{
    border: 1px solid var(--textColorAlt);
    color: var(--textColorAlt);
    background-color: #f13343;
}

.faq-success-alert{
    border: 1px solid var(--textColorAlt);
    color: var(--textColorAlt);
    background-color: #15aadb;
}


.submit-btn{
    padding: 0.5rem 1rem;

    background-color: var(--templateContrast);
    border: none;
    border-radius: 20px;
    outline: none;

    color: var(--templateBackground);
    cursor: pointer;
    font-size: 0.8rem;
    transition: 0.3s ease-in-out;
}

.faq-submit-btn{
    outline: 1px solid var(--textColorAlt);
    background-color: var(--templateBackground);
    color: var(--textColorAlt);
}

.submit-btn:hover{
    background-color: var(--templateBackground);
    outline: 1px solid var(--textColorAlt);
    /* border-radius: 20px; */
    color:  var(--textColorAlt);
}
.faq-submit-btn:hover{
    background-color: var(--templateContrast);
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 786px ){
    .form-container{
        width: 100%;
        margin-top: 0rem;
    }

    .form-grid{
        width: 100%;
        grid-template-columns: 100%;
        /* border: 0.5px solid red; */
        /* grid-template-rows: auto auto auto; */
    }

    .form-group{
        grid-column: span 2;
    }

    .form-group>label{
        font-size: 0.5rem;
    }

    .form-control{
        font-size: 0.5rem;
    }

    /* .alert-submit-box{
        grid-column:1/ span 2,
    } */
    .error-alert{
        top:0.5rem;
    }

    .submit-btn{
        font-size: 0.7rem;
    }

    .error-content{
        font-size: 0.7rem;
        margin-bottom:5vh;
    }
}