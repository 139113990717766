
.img-text{
    position: absolute;
    bottom: 2rem;
    left: 0;

    width: 80%;
    padding-left: 5rem;
}

.faq-window-content{
    min-width: 12vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 0.5px solid red; */
}

.faq-window-content>h1{
    text-align: left;
}

.faq-nav-btn{
    display: inline-block;
    font-weight: normal;
    /* border: 0.5px solid red; */
}

.faq-h1-slug{
    /* color: var(--textColorContrast); */
    font: inherit;
    justify-self: flex-start;
    text-align: left;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}


.faq-slug{
    color: var(--textColorContrast);
    font: inherit;
    font-weight: lighter;
    opacity: 1;
    /* transform: translateX(0); */
    transition: all 0.3s ease-in;
}

.faq-hidden{
    opacity: 0;
    font: inherit;
    font-weight: lighter;
    /* transform: translateX(100px); */
    transition: all 0s ease-in-out;
}

.big-image-text>p{
    font-size: 4rem;
}

.faq-text{
    width: 60%;

    /* border: 1px solid red; */
}

.big-faq{
    font-size: 3rem;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
    .img-text{
        padding-left: 2rem;
    }
    .faq-nav-btn>span{
    font-size: 0.8rem;
    }
    .faq-text{
        width: 80%;
    }

    .big-image-text>p{
        font-size: 2rem;
    }

    .big-faq{
        font-size: 2rem;
    }

    .big-faq>p{
        font-size: 1.5rem;
    }
}