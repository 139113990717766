.overlay-container{
  position: absolute;
  top:0;
  left: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--templateContrast);
  z-index: 10;
}

.panel1{
  background-color: var(--templateContrast);

  animation: panel1-bg-anim 0.6s ease-in-out;
}

.panel2{
  background-color: var(--templateBackground);

  animation-name: panel2-bg-anim;
  animation-duration: 0.6s;
  animation-delay: 0.8s;
  animation-fill-mode:  both;
  animation-timing-function: ease-in-out;
}

.panel1-text{
  background-color: transparent;

  animation: panel1-text-anim 0.6s ease-in-out;
}

.panel2-text{
  background-color: transparent;

  animation-name: panel2-text-anim;
  animation-duration: 0.8s;
  animation-delay: 0.8s;
  animation-fill-mode:  both;
  animation-timing-function: ease-in-out;
}

@keyframes panel1-bg-anim{
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

@keyframes panel2-bg-anim{
  from {
    top: -100vh;
  }
  to {
    top: 0;
  }
}

@keyframes panel1-text-anim{
  0% {
    left: -5vw;
  }
  100% {
    left: 0;
  }
}

@keyframes panel2-text-anim{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.overlay-container>h1{
  /* font-family: 'Playfair', serif; */
  font-family: 'Manrope', sans-serif;
  font-weight: normal;
  font-size: 3.5rem;
}

.overlay-container>p{
  font-size: 2rem;
}

.overlay-fade-out{
  opacity: 0;
  transition: all 0.3s ease-out;
}

.fake-backdrop{
    position: absolute;
    top:0;

    width: 100%;
    height: clamp(80px, 8vh, 8vh);

    background-color: rgb(80, 15, 15);

    filter: brightness(10%);
    -webkit-filter: brightness(10%);

    transition: 0.3s ease-in-out;

    /* border: 2px solid red; */
}

.loading-logo-img{
  width: 10vw;
  aspect-ratio: 1/1;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
  .overlay-container>h1{
    font-size: 2rem;
  }
  .overlay-container>p{
    font-size: 1rem;
  }

  .loading-logo-img{
    width: 15vw;
  }
}

@media only screen and (max-width: 786px ){
  .loading-logo-img{
    width: 30vw;
  }
}