@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair:opsz,wght@5..1200,300;5..1200,400;5..1200,500;5..1200,600;5..1200,700;5..1200,800&display=swap');

@font-face{
  src: url(./fonts/Sabon.ttf);
  font-family: Sabon;
}

html {
  scroll-behavior: smooth;
}

:root{
  --templateBackground: white;
  --templateBackgroundAlt: #F6F8D8;
  /* f6f8e2 */
  /* f6e7cb */
  /* F6F8D8 */
  --templateContrast: #30C966;
  /* 54B566 */
  /* 30C966 */
  --textColour: #54B566;
  --textColorAlt: #3c3d42;
  --textColorContrast:#588157;
  /* --textColorAlt: #e0ddca; */
  /* --textColour: white; */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;

  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

a{
  text-decoration: none;
  color: inherit;
}

section>p>a{
  color: var(--templateContrast);
}

.container{
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-color: var(--templateBackground);
  color: var(--textColour);
}

.content-container{
  position: relative;
  width: 98%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5vh 2rem;

  background-color: var(--templateContrast);

  border-radius: 25px;
}

.hover-underline-animation-btn {
  display: inline-block;
  position: relative;
  font-size: 1.5rem;
  color: var(--templateBackground);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hover-underline-animation-btn::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--templateBackground);
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}

.hover-underline-animation-btn:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.alt-btn{
  color: var(--textColorAlt);
} 
.alt-btn::after, .alt-btn:hover::after{
  background-color: var(--textColorAlt);
}

/*///////////////////////////////////////////////*/
/*/////////////////ScrollBar/////////////////////*/
/*///////////////////////////////////////////////*/
 
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #e24545; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
  .container{
    width: 100% ;
    /* border: 1px solid blue; */
  }

  .hover-underline-animation-btn{
    font-size: 1rem;
  }
}