.accordian-container{
    position: relative;

    width: 60%;
    min-width: 40vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 5vh;

    z-index: 0;

    /* border:0.5px solid red; */
}


.accordian-items{
    position: relative;
    width: 100%;
    height: auto;

    /* border: 2px solid blue; */
    /* border-bottom: 0.5px solid green; */

    padding: 1rem 2rem;
    
    border-radius: 20px;
    transition: 0.6s ease-in-out;
}

.question{
    position: relative;
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    padding: 0 1rem 1rem;

    cursor: pointer;
    /* border: 0.5px solid blue; */
}

.question>h3{
    font-weight: normal;
    /* border: 2px solid red; */
}

.question::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 10px;

    bottom: -2px;
    left: 1rem;
    /* background-color: rgba(49, 143, 91, 0.6); */
    background-color: rgba(0, 128, 0, 0.2);
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform 0.6s ease-in-out;
}

.question:hover::before{
    transform: scaleX(0.8);
    transform-origin: bottom left;
}

.view-btn{
    transition: 0.4s ease-in-out;   
    /* border: 0.5px solid red; */
}

.up-btn{
    transform: rotate(180deg);
    transition: 0.4s ease-in-out;
}

.answer{
    padding: 1rem 2rem 0;
    font-size: 1rem;
    height: auto;

    transition: 0.4s ease-in-out;
    /* z-index: 1; */
    /* border: 0.5px solid purple; */
}

.answer>span>p,.answer-inactive>span{
    transition: 0.4s ease-in-out;
    /* border: 0.5px solid purple; */
}

.answer-inactive{
    /* display:none; */
    padding: 1rem 2rem 0;
    height: 0;
    font-size: 1rem;
    /* z-index: -2; */

    transition: 0.4s ease-in-out;
}

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
    .accordian-container{    
        width: 90%;
        /* border: 0.5px solid blue; */
    }

    .question{
        padding: 0 0rem 0rem;
    }
    .question::before{
        height: 5px;
    
        bottom: -10px;
        left: 0rem;
    }

    .question>h3{
        font-size: 1rem;
        /* border: 0.5px solid red; */
    }

    .answer, .answer-inactive{
        padding: 1rem 0.5rem 0;
        font-size: 0.7rem;
    }
}