.footer-container{
    position: relative;

    width: 100%;
    min-height: 40vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin-top: 5vh;
    padding-top: 5vh;

    background-color: var(--templateContrast);

}

.footer-content{
    margin-bottom: 2rem;

    width: 100%;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    /* border: 2px solid red; */
}

.footer-content-item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 5vh;

    width: 100%;

    color: var(--templateBackground);

    /* border: 2px solid blue; */
}

.logo{
    /* border: 1px solid red; */
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.logo>h1{
    font-size: 2.5rem;
    cursor: pointer;
}

.footer-logo{
    margin-left: 0.5rem;

    width: 3.5rem;
    aspect-ratio: 1/1;

    cursor: pointer;
    /* border: 1px solid blue; */
}

.center>article>section{
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
    /* align-items: center; */
    /* justify-content: center; */
    /* align-items: flex-start; */
}

.shortcuts{
    align-items: flex-start;
    color: var(--templateBackground);
}

.shortcuts-list{
    display: grid;
    grid-template-columns: auto auto;
}

.shortcuts-list>h3{
    padding: 0.5rem;
    font-size: 0.8rem;
    cursor: pointer;
}

.copyright-container{
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
    /* color: white; */
    color: var(--templateBackground);
}   

/*//////////Responsive Design///////////*/
@media only screen and (max-width: 1000px ){
    .logo{
        align-items: center;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .logo>h1{
        font-size: 1.2rem;
    }
    
    .footer-logo{
        margin-top: 0.5rem;
    
        width: 2.5rem;
        aspect-ratio: 1/1;
    }

    .center>article>section>p>a{
        font-size: 0.7rem;
    }   

    .shortcuts-list>h3{
        font-size: 0.5rem;
    }
}